import React, { useEffect, useState } from "react";
import "./navbar.scss";
import { Api_Url } from "../../../utils/ApiUrl";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Web3 from "web3";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import useAuth from "../../../hooks/useAuth";
import { useWeb3React } from "@web3-react/core";
import Multiselect from "multiselect-react-dropdown";

const Navbar = ({
  sidebar,
  setSidebar,
  GetMyData,
  user,
  mobileNav,
  indexwait,
  setindexwait,
}) => {
  const { account } = useWeb3React();
  // console.log("account we get here is",account)
  const [log, setLog] = useState(false);
  const [insName, setInsName] = useState("");
  const [insWallet, setInsWallet] = useState("");
  const [insEmail, setInsEmail] = useState("");
  const [insPrice, setInsPrice] = useState("");

  const history = useHistory();
  const { login, logout } = useAuth();
  const Logout = async () => {
    localStorage.removeItem("indexvalue");
    const val = localStorage.getItem("accessToken");
    var config = {
      method: "delete",
      url: `${Api_Url}/auth/users/logout`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    axios(config)
      .then(function (response) {
        if (response?.data?.statusCode == 200) {
          localStorage?.removeItem("accessToken");
          localStorage?.removeItem("refreshToken");
          localStorage?.removeItem("userName");

          history.push("/login");
        }
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/login");
        }
      });
  };

  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [agents, setAgents] = useState([]);
  const [name, setName] = useState("");
  const [role, setRole] = useState(null);
  const [addr, setAddr] = useState("");
  const [email, setEmail] = useState("");
  const [specialAccess, setSpecialAccess] = useState([]);
  const [godLeadersList, setGodLeadersList] = useState([]);
  const [isToggled, setIsToggled] = useState(false);

  const [error, setError] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => {
    setName("");
    setAddr("");
    setEmail("");
    // setRole(null);
    setShow1(false);
  };

  const [show2, setShow2] = useState(false);
  const [show121, setShow121] = useState(false);
  const handleClose2 = () => {
    setShow2(false);
    setRole(null);
  };
  function isValidEmail(email) {
    var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  }

  function isAddressValid(address) {
    return Web3.utils.isAddress(address);
  }

  useEffect(() => {
    if (isToggled) {
      specialAccessList();
    }
  }, [isToggled]);

  const specialAccessList = () => {
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "get",
      url: `${Api_Url}/users/god-leaders?limit=1000`,

      headers: {
        Authorization: "Bearer " + val,
      },
    };
    axios(config)
      .then(function (response) {
        setGodLeadersList(response?.data?.data);
      })
      .catch(function (error) {
        // toast.error(error?.response?.data?.message);
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
        }
      });
  };

  const AddAgent = () => {
    const lowerCaseEmail = email.toLowerCase();

    const val = localStorage.getItem("accessToken");
    let url = null;
    if (user?.role === "admin") {
      url = `${Api_Url}/users/god-agents`;
    } else if (role === "mega_agent") {
      url = `${Api_Url}/users/mega-agents`;
    } else if (role === "super_agent") {
      url = `${Api_Url}/users/super-agents`;
    } else if (role === "agent") {
      url = `${Api_Url}/users/agents`;
    } else if (role === "mini_agent") {
      url = `${Api_Url}/users/mini-agents`;
    }
    if (name?.length > 2) {
      if (isAddressValid(addr)) {
        if (isValidEmail(email)) {
          setLoader(true);
          var config = {
            method: "post",
            url: url,
            data: {
              name: name,
              walletAddress: addr,
              email: lowerCaseEmail,
              name: name,
            },
            headers: {
              Authorization: "Bearer " + val,
            },
          };
          axios(config)
            .then(function (response) {
              setLoader(false);
              GetMyData();
              setName("");
              setAddr("");
              setEmail("");
              // setRole(null);
              handleClose1();
              setShow2(true);
              // GetAgents();
            })
            .catch(function (error) {
              setLoader(false);
              setRole(null);

              toast.error(error?.response?.data?.message);
              if (error?.response?.status == 401) {
                localStorage.removeItem("accessToken");
                history.push("/");
              }
            });
        } else {
          setError("Incorrect Email!");
        }
      } else {
        setError("Incorrect wallet address!");
      }
    } else {
      setError("Name must be of length 3!");
    }
  };

  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }

  const trustWallet = async () => {
    setShoww(false);
    // handleShow()
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId");
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      login("walletconnect");
      localStorage.setItem("connectorId", "walletconnect");
      localStorage.setItem("flag", "true");
      setLog(true);
    }
  };

  const [showw, setShoww] = useState(false);
  const handleClosew = () => setShoww(false);
  const handleShoww = () => setShoww(true);

  const connectMetaMask1 = async () => {
    setShoww(false);
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId");
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      login("injected");
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      setLog(true);
    }
  };
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (setShoww) {
      setShoww(true);
    }
  };

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const [show111, setShow111] = useState(false);
  const handleClose111 = () => setShow111(false);
  const [show222, setShow222] = useState(false);
  const handleClose222 = () => setShow222(false);

  const AddInstitution = () => {
    if (insName?.length > 2) {
      if (Web3.utils.isAddress(insWallet)) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(insEmail)) {
          if (parseFloat(insPrice) > 0) {
            setLoader(true);
            setError("");
            const val = localStorage.getItem("accessToken");
            let data = JSON.stringify({
              name: insName,
              walletAddress: insWallet,
              email: insEmail,
              dopPrice: insPrice,
            });
            let config = {
              method: "post",
              url: `${Api_Url}/institutions`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${val}`,
              },
              data: data,
            };
            axios
              .request(config)
              .then((response) => {
                setLoader(false);
                setInsEmail("");
                setInsName("");
                setInsPrice("");
                setInsWallet("");
                handleClose111();
                setShow121(true);
              })
              .catch((error) => {
                setError(error?.response?.data?.message);
                setLoader(false);
              });
          } else {
            setError("Institution INCENTIV price should be greater than 0!");
          }
        } else {
          setError("Institution email address is incorrect!");
        }
      } else {
        setError("Institution wallet address is incorrect!");
      }
    } else {
      setError("Institution name must be at least of length 3!");
    }
  };

  const SetPriceDOP = (val) => {
    let valu = parseFloat(val);
    if (valu > 0) {
      setInsPrice(parseFloat(valu));
    } else {
      setInsPrice(0);
    }
  };

  return (
    <>
      {!mobileNav ? (
        <>
          <section className="main-navbar">
            <div className="custom-container">
              <nav class="navbar navbar-expand-lg">
                <div className="twice-icons">
                  <a
                    onClick={() => setSidebar(!sidebar)}
                    class="navbar-brand d-noneonmobile"
                  >
                    <img
                      src={
                        sidebar ? "/assets/close-icon.svg" : "/assets/bar.svg"
                      }
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                  <a
                    class="navbar-brand"
                    onClick={() => {
                      setindexwait(0);
                    }}
                  >
                    <img src="\logo.svg" alt="img" className="img-fluid" />
                  </a>
                </div>
                <button
                  onClick={() => setSidebar(!sidebar)}
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <img
                    src={sidebar ? "/assets/close-icon.svg" : "/assets/bar.svg"}
                    alt="img"
                    className="img-fluid"
                  />
                </button>
                <div
                  class="collapse navbar-collapse justify-content-end"
                  id="navbarSupportedContent"
                >
                  {user?.role === "admin" &&
                  (indexwait === 5 || indexwait === 6 || indexwait === 7) ? (
                    <button
                      className="add-leader"
                      style={{ padding: "13px 16px" }}
                      onClick={setShow111}
                    >
                      Add Institution
                    </button>
                  ) : (
                    <>
                      {user?.role === "mini_agent" ? (
                        ""
                      ) : (
                        <button
                          className="add-leader"
                          style={{ padding: "13px 16px" }}
                          onClick={() => setShow1(true)}
                        >
                          Add Leader
                        </button>
                      )}
                    </>
                  )}
                  <div className="btnss dvygdyvdv">
                    {!account ? (
                      <button
                        className="connect-btn"
                        onClick={() => {
                          handleClosew();
                          handleShow();
                        }}
                      >
                        Connect Wallet
                      </button>
                    ) : (
                      <button
                        className="dissconnect-btn"
                        onClick={connectMetaMask1}
                      >
                        Disconnect
                      </button>
                    )}
                  </div>
                  <button onClick={() => Logout()} className="logout-btn">
                    Logout
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="11"
                      viewBox="0 0 13 11"
                      fill="none"
                    >
                      <path
                        d="M6.18316 0.529297V1.97395H7.62783V0.529297H6.18316ZM7.62783 1.97395V3.41791H9.07178V1.97395H7.62783ZM9.07178 3.41791V4.86256H0.151611V6.30442H9.07178V7.74836H10.5164V6.30442H12.32V4.86256H10.5164V3.41791H9.07178ZM9.07178 7.74836H7.62783V9.19303H9.07178V7.74836ZM7.62783 9.19303H6.18316V10.637H7.62783V9.19303Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </nav>
            </div>
          </section>
          <Modal
            className="add-mega-modal global-modal-scss"
            show={show1}
            onHide={handleClose1}
            centered
          >
            <Modal.Header closeButton>
              {user?.role === "admin" ? (
                <Modal.Title>Add God Leader</Modal.Title>
              ) : (
                <Modal.Title>Add Leader</Modal.Title>
              )}
              {/* {user?.role === "admin" && <Modal.Title>Add Mega Agents</Modal.Title>}
                    {user?.role === "admin" && <Modal.Title>Add Mega Agents</Modal.Title>} */}
            </Modal.Header>
            <Modal.Body>
              <div className="add-mega-div">
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter Leader’s Name"
                    />
                    <label>Leader’s Name</label>
                  </div>
                </div>
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      type="text"
                      value={addr}
                      onChange={(e) => setAddr(e.target.value)}
                      placeholder="Enter Leader’s Wallet Address"
                    />
                    <label>Leader’s Wallet Address</label>
                  </div>
                </div>
                <div className="option-field">
                  <div className="material-textfield">
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="text"
                      placeholder="Enter Leader’s Email Address"
                    />
                    <label>Leader’s Email Address</label>
                  </div>
                </div>
                {user?.role !== "admin" && (
                  <div className="option-field">
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {role === null
                          ? "Type of Leader"
                          : convertToTitleCase(role)}
                      </button>
                      {user?.role === "god_agent" && (
                        <ul class="dropdown-menu">
                          <li onClick={() => setRole("mega_agent")}>
                            <a class="dropdown-item" href="#">
                              Mega Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("super_agent")}>
                            <a class="dropdown-item" href="#">
                              Super Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("agent")}>
                            <a class="dropdown-item" href="#">
                              Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("mini_agent")}>
                            <a class="dropdown-item" href="#">
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      )}
                      {user?.role === "mega_agent" && (
                        <ul class="dropdown-menu">
                          <li onClick={() => setRole("super_agent")}>
                            <a class="dropdown-item" href="#">
                              Super Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("agent")}>
                            <a class="dropdown-item" href="#">
                              Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("mini_agent")}>
                            <a class="dropdown-item" href="#">
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      )}
                      {user?.role === "super_agent" && (
                        <ul class="dropdown-menu">
                          <li onClick={() => setRole("agent")}>
                            <a class="dropdown-item" href="#">
                              Leader
                            </a>
                          </li>
                          <li onClick={() => setRole("mini_agent")}>
                            <a class="dropdown-item" href="#">
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      )}
                      {user?.role === "agent" && (
                        <ul class="dropdown-menu">
                          <li onClick={() => setRole("mini_agent")}>
                            <a class="dropdown-item" href="#">
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  marginTop: -15,
                  marginBottom: 15,
                }}
              >
                {error}
              </p>
              {user?.role == "admin" ? (
                <>
                  <div className="special-access">
                    {/* <div className="parent-toggle-switch-custom">
                      <div class="custom-toggle">
                        <input
                          type="checkbox"
                          class="checkbox"
                          id="checkbox"
                          onChange={handleToggle}
                          checked={isToggled}
                        />
                        <label class="switch" for="checkbox">
                          <span class="slider"></span>
                        </label>
                      </div>
                    </div>
                    <h6>Special Access</h6> */}
                  </div>
                  {isToggled && (
                    <div className="multiselect-custom">
                      <label className="custom-label">Special Access to</label>
                      <Multiselect
                        placeholder=""
                        isObject={false}
                        onKeyPressFn={function noRefCheck() {}}
                        onRemove={function noRefCheck() {}}
                        onSearch={function noRefCheck() {}}
                        onSelect={function noRefCheck(selectedName) {
                          const selectedLeader = godLeadersList.find(
                            (leader) => {
                              if (
                                leader.name ==
                                selectedName[selectedName?.length - 1]
                              ) {
                                return true;
                              }
                            }
                          );
                          if (selectedLeader) {
                            const selectedId = selectedLeader._id;
                            // Do whatever you need to with the selected id
                          }
                        }}
                        customCloseIcon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M5.14307 12.8571L12.8574 5.14285"
                              stroke="black"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12.8574 12.8571L5.14307 5.14285"
                              stroke="black"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        }
                        options={godLeadersList?.map((item) => item?.name)}
                        // options={godLeadersList?.name}
                      />
                    </div>
                  )}
                </>
              ) : null}

              <div className="twice-btn">
                <button
                  onClick={() => {
                    handleClose1();
                    setError("");
                  }}
                  className="btn-cancel"
                >
                  Back
                </button>
                <button
                  disabled={loader}
                  className="btn-add"
                  onClick={() => AddAgent()}
                >
                  {loader ? "Adding..." : "Add"}
                </button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            className="add-mega-modal global-modal-scss"
            show={show2}
            onHide={handleClose2}
            centered
          >
            <Modal.Header closeButton>
              {user?.role === "admin" ? (
                <Modal.Title>Add God Leader</Modal.Title>
              ) : (
                <Modal.Title>Add Leaders</Modal.Title>
              )}
            </Modal.Header>
            <Modal.Body>
              <div className="add-mega-div">
                {user?.role === "admin" ? (
                  <h6>God Leader has been added</h6>
                ) : (
                  <h6>
                    {convertToTitleCase(role)}{" "}
                    {convertToTitleCase(role) === "Leader"
                      ? "has been added"
                      : convertToTitleCase(role) === "Mega Leader" ||
                        convertToTitleCase(role) === "Ambassador"
                      ? "has been added"
                      : "Leader has been added"}
                  </h6>
                )}
              </div>
              <div className="twice-btn">
                <button
                  onClick={() => handleClose2()}
                  className="btn-add"
                  style={{ flex: "auto" }}
                >
                  Okay
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            className="add-mega-modal global-modal-scss"
            show={show121}
            onHide={() => setShow121(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Create Institution</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="add-mega-div">
                <h6>Institution has been created</h6>
              </div>
              <div className="twice-btn">
                <button
                  onClick={() => setShow121(false)}
                  className="btn-add"
                  style={{ flex: "auto" }}
                >
                  Okay
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            className="connectwallet-modal"
            show={showw}
            onHide={handleClosew}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Connect Wallet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="twice-btns">
                <button className="forhideee" onClick={connectMetaMask1}>
                  <img
                    src="\assets\metamask.svg"
                    alt="img"
                    className="img-fluid"
                  />
                  <h6>Metamask</h6>
                </button>
                <button onClick={trustWallet}>
                  <img
                    src="\assets\walletconnect.svg"
                    alt="img"
                    className="img-fluid"
                  />
                  <h6>WalletConnect</h6>
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <>
          <div className="btnss dvygdyvdv mobile-only-set">
            {!account ? (
              <button
                className="connect-btn"
                onClick={() => {
                  handleClosew();
                  handleShow();
                }}
              >
                Connect Wallet
              </button>
            ) : (
              <button className="dissconnect-btn" onClick={connectMetaMask1}>
                Disconnect
              </button>
            )}
          </div>
          <Modal
            className="connectwallet-modal"
            show={showw}
            onHide={handleClosew}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Connect Wallet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="twice-btns">
                <button className="forhideee d-none" onClick={connectMetaMask1}>
                  <img
                    src="\assets\metamask.svg"
                    alt="img"
                    className="img-fluid"
                  />
                  <h6>Metamask</h6>
                </button>
                <button
                  onClick={() => {
                    trustWallet();
                    setSidebar(false);
                  }}
                >
                  <img
                    src="\assets\walletconnect.svg"
                    alt="img"
                    className="img-fluid"
                  />
                  <h6>WalletConnect</h6>
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}

      <Modal
        className="add-mega-modal global-modal-scss"
        show={show111}
        onHide={handleClose111}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Institution</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-mega-div">
            <div className="option-field">
              <div className="material-textfield">
                <input
                  value={insName}
                  onChange={(e) => setInsName(e.target.value)}
                  type="text"
                  placeholder="Enter Institution’s Name"
                />
                <label>Institution’s Name</label>
              </div>
            </div>
            <div className="option-field">
              <div className="material-textfield">
                <input
                  value={insWallet}
                  onChange={(e) => setInsWallet(e.target.value)}
                  type="text"
                  placeholder="Enter Institution’s Wallet Address"
                />
                <label>Institution’s Wallet Address</label>
              </div>
            </div>
            <div className="option-field">
              <div className="material-textfield">
                <input
                  value={insEmail}
                  onChange={(e) => setInsEmail(e.target.value)}
                  type="text"
                  placeholder="Enter Institution’s Email Address"
                />
                <label>Institution’s Email Address</label>
              </div>
            </div>
            <div className="option-field">
              <div className="material-textfield">
                <input
                  value={parseFloat(insPrice)}
                  onChange={(e) => SetPriceDOP(e.target.value)}
                  type="number"
                  placeholder="0.00"
                />
                <label>INCENTIV Price</label>
              </div>
            </div>
          </div>
          <p
            style={{
              color: "red",
              textAlign: "center",
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            {error}
          </p>
          <div className="twice-btn">
            <button
              onClick={() => {
                handleClose1();
              }}
              className="btn-cancel"
            >
              Back
            </button>
            <button onClick={() => AddInstitution()} className="btn-add">
              {loader ? "Adding..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="add-mega-modal global-modal-scss"
        show={show222}
        onHide={handleClose222}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Institution</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-mega-div">
            <h6>Institution has been added</h6>
          </div>
          <div className="twice-btn">
            <button
              onClick={() => handleClose2()}
              className="btn-add"
              style={{ flex: "auto" }}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;
