let Environment = {
  PreSale: "0x20361bC401EA01e5d6E01596c445Af1a48e1171b",
  Claim: "0x27E00382b0C3eCd74d1505e8CD73706d79D0Bd54",
  InstitutionPreSale: "0x09e4f3E65ce7c16566DdFA0D860D4d95a26698ca",
  Graph: "https://api.studio.thegraph.com/query/63987/incentive-main/v1.1.1.1",
  institutionGraph:
    "https://api.studio.thegraph.com/query/63987/incentive-institution-mainnet/01",
  // Graph:
  //   "https://api.thegraph.com/subgraphs/name/saifdevblochain/gemsambassador", //previous graph for factory's claim contract

  busd: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  tomi: "0x4385328cc4D643Ca98DfEA734360C0F596C83449",
  eth: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  usdc: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  wbtc: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
  pepe: "0x6982508145454Ce325dDbE47a25d4ec3d2311933",
  link: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
  uni: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
  dop: "0x97A9a15168C22B3C137E6381037E1499C8ad0978",
};
export default Environment;
